<template>
  <div class="auth-wrapper">
    <div class="auth-inner">
      <form class="form" @submit.prevent="confirm">
        <h3 class="title">Smsni tasdiqlash</h3>
        <div class="form-group">
          <label>Tasdiqlash kodi</label>
          <input
            v-model="code"
            required
            autofocus
            type="text"
            class="form-control"
          />
        </div>
        <button class="btn btn-orange btn-block">Saqlash</button>
        <p v-if="!sent" class="forgot-password text-right">
          <span class="link" @click="sendSms">SMS reply</span>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmation",
  data: function () {
    return {
      code: "",
      sent: true,
    };
  },
  computed: {
    tempToken() {
      return this.$store.getters["user/getTempToken"];
    },
  },
  methods: {
    async confirm() {
      if (await this.$store.dispatch("user/confirm_phone", this.code)) {
        await this.$router.push({ name: "finish" });
      }
    },
    async sendSms() {
      if (await this.$store.dispatch("user/send_sms")) {
        this.$toast("Sms jo'natildi");
        this.sent = true;
        setTimeout(() => {
          this.sent = false;
        }, 10000);
      } else {
        this.$toast.error("Sms jo'natilmadi.");
      }
    },
  },
  mounted() {
    if (this.tempToken !== "") {
      this.sendSms();
    } else {
      this.$router.push({ name: "register" });
    }
  },
};
</script>

<style scoped></style>
